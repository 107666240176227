import styles from './Contents.module.scss';
import All from './All'
import Medical from './Medical';
import Public from './Public';
import Manufacturing from './Manufacturing';
import { SwitchTransition, CSSTransition } from 'react-transition-group';


const items = [
  {
    categroy: 'Medical',
    title: 'Comparison of accuracy among multiple channels with deep learning for automated sleep staging',
    author: 'Seong Jae Lee',
    publication: '-',
    detail: [
      { 
        Introduction: '수면다원검사(polysomnography, 이하 PSG)는 수면 단계를 식별하고 수면장애의 진단에 필수적인 검사입니다. 그러나 수면 단계 수동 측정 작업은 인력과 시간이 많이 소요되는 노동집약적 작업입니다. 그렇기에 최근에는 딥러닝을 통한 수면 단계 자동 측정 방법이 매우 인기를 얻고 있습니다. 이러한 수면 단계 자동 측정의 기존 연구들은 Sleep-EDF의 Fpz-Cz 또는 자체적인 6개 채널의 데이터를 사용했지만, 우리는 다양한 채널의 추가 사용이 성능을 개선할 수 있다고 생각하였습니다. 그렇기에 우리는 다중 채널 조합 방법론을 소개하며, 다중 채널을 기반으로 훈련된 딥러닝 네트워크를 소개합니다. 이 네트워크는 최종 성능 평가에서 F1 score 0.88을 달성할 수 있습니다.',
        MaterialAndMethod: '본 연구에서 사용된 데이터는 순천향대학교 천안병원에서 자체적으로 수집된 2019년부터 2021년까지 수행된 213개의 환자 PSG 데이터를 활용하였습니다. 환자 PSG 데이터는 200 Hz/초의 간격으로 수집되었으며, 이 데이터는 epoch라는 30초 단위로 분할되었습니다. 또한 각 epoch은 앞뒤의 epoch에서 15초씩 겹쳐지도록 overlap되었습니다.       이후 우리는 overlap된 시퀀스 데이터의 계산 비용을 최소화하기 위해 균등 샘플링을 활용하여 데이터의 크기를 압축하여 활용했습니다. 또한 기존 6개의 채널과 함께 다중 채널 조합 방법을 통해 생성된 4개의 신규 채널을 결합한 11개의 채널(10개 + LOC채널)을 사용하여 네트워크를 훈련하고 평가하였습니다. 다중 채널 조합 방법의 검증을 위해 활용한 네트워크의 전체 구조는 시계열 데이터의 핵심적인 특징 추출 및 압축을 위한 1차원 합성곱 신경망과 시계열 데이터 사이의 연관성을 효과적으로 분석하기 위한 양방향 장단기 메모리(Bidirectional Long-Short Term Memory)를 기반으로 설계되었습니다. 마지막으로 본 연구에서 제안한 다중 채널 조합 방법 및 모델의 평가는 10-fold 교차 검증을 사용하여 수행되었습니다.',
        Results: '교차 검증을 기반으로 학습된 네트워크의 일반화 성능 평가를 위해 학습에 사용되지 않은 추가 테스트 세트를 사용하여 결과를 도출했습니다. 본 연구에서 제안하는 다중 채널 조합 방법론을 사용한 네트워크는 최종 성능 평가에서 W의 F1 스코어가 0.89, N1의 F1 스코어가 0.64, N2의 F1 스코어가 0.92, N3의 F1 스코어가 0.85, REM의 F1 스코어가 0.91로 도출되었으며, 개별 채널 예측 성능을 종합한 전반적인 네트워크의 성능은 F1 스코어 0.88을 달성할 수 있었습니다.',
        Conclusion: '본 연구 결과를 통해 우리는 다중 채널 조합 방법론을 통한 채널 데이터가 추가됨에 따라 수면 단계 자동 판독 성능이 향상될 것으로 나타났습니다. 이러한 연구 결과는 기존 데이터 활용 방법보다 일관되고 표준화된 결과를 얻을 수 있어 수면 단계 분류의 정확성과 신뢰성이 향상될 수 있음을 시사합니다.', 
        Authors: ['Seung Cheol Lee1, Seong Jae Lee2, Seong-Jin Ju1, Hyo-Jeong Son1, Sang-Myung Son1, Jongkyu Park1, Seung Soo Kim3, Jae Hee Jeong2, Kwang Ik Yang1', 'Sleep Disorders Center, Department of 1Neurology and 3Pediatrics, Soonchunhyang University College of Medicine, Cheonan Hospital, 3Human Deep Inc.'],
        Published: '-',
        Link: 'https://drive.google.com/file/d/10qhffExJULHTZ_hlyRPVz1Ywv5yU8wTB/view?usp=drive_link'
      }
    ]
  },
  // {
  //   categroy: 'Medical',
  //   title: 'Immune phenotypes classified by deep learning-based H&E tissue analyzer demonstrate distinct immune landscape and transcriptomic features in ovarian cancer',
  //   author: 'jonggwan_kim',
  //   publication: '-'
  // },
  // {
  //   categroy: 'Public',
  //   title: 'Immune phenotypes classified by deep learning-based H&E tissue analyzer demonstrate distinct immune landscape and transcriptomic features in ovarian cancer',
  //   author: 'jonggwan_kim',
  //   publication: '2023-06-30'
  // },
  // {
  //   categroy: 'Manufacturing',
  //   title: 'Immune phenotypes classified by deep learning-based H&E tissue analyzer demonstrate distinct immune landscape and transcriptomic features in ovarian cancer',
  //   author: 'jonggwan_kim',
  //   publication: '2023-10-24'
  // },
]

function filter(value: string) {
  const temp = []
  for (let i = 0; i < items.length; i++) {
    if (items[i].categroy === value) {
      temp.push(items[i])
    }
  }
  return temp
}

const activateComponent = (props: string) => {
  if (props === 'All') {    
    return <All items={items}/>
  } else if (props === 'Medical') {
    return <Medical items={filter('Medical')}/>
  } else if (props === 'Public') {
    return <Public items={filter('Public')}/>
  }
  return <Manufacturing items={filter('Manufacturing')}/>
}

const Contents = (props: object|any) => {  

  return (
    <div className={styles.article_section} >
      <div className={styles.article_title}>
        <h1 >Publications</h1>
      </div>
      <SwitchTransition mode='out-in'>
        <CSSTransition key={props.activateComponent} timeout={200} classNames='fade'>
          { activateComponent(props.activateComponent) }
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Contents;